import Panel from "../layout/panel/panel"
import CategoriesCmp from "../components/categories/categories"

const Categories = () => {
  return (
    <Panel>
      <CategoriesCmp />
    </Panel>
  )
}

export default Categories
