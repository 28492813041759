import "react-quill/dist/quill.snow.css"
import "./products.css"
import React, { memo, useState } from "react"
import { useHistory } from "react-router-dom"
import ReactQuill from "react-quill"
import ClipLoader from "react-spinners/ClipLoader"
import { LOADER_OVERRIDE, QUILL_TOOLBAR } from "../../utils/constants"
import { Notifications } from "../../helper/notifications"
import Button from "../../common/Button"
import Api from "../../api/api"

const EditProduct = ({ id, categories, data, setData }) => {
  const history = useHistory()
  const [isDisable, setIsDisable] = useState(false)

  const inputHandle = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const setImageHandle = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setData((prev) => ({
          ...prev,
          imageURL: event.target.result,
          image: event.target.result,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)

    let res = await Api.editProduct({ ...data, id })
    if (res.status == 200) {
      history.push("/products")
      Notifications("success", res.data.message)
    }
    setIsDisable(false)
  }

  return (
    <div
      className="add_product_cont"
      style={{ height: "100%" }}
    >
      <div
        className="c_card"
        style={{ height: "100%", overflowY: "scroll" }}
      >
        <form
          onSubmit={submitHandle}
          className="profile_form product_form"
        >
          <div className="profile_input_wrapper">
            <label>Product Image</label>
            <div className="input_wrapper">
              <input
                type="file"
                name="image"
                onChange={setImageHandle}
                placeholder="Product Image"
                accept="image/png, image/jpeg, image/jpg"
              />
            </div>
            {data.imageURL && (
              <img
                src={data.imageURL}
                style={{
                  marginLeft: 12,
                  width: 100,
                  aspectRatio: 4 / 3,
                  objectFit: "fill",
                }}
                alt=""
              />
            )}
          </div>
          <div className="profile_input_wrapper">
            <label>Product Name</label>
            <div className="input_wrapper">
              <input
                type="text"
                name="name"
                value={data.name}
                onChange={inputHandle}
                placeholder="Product Name"
                required
              />
            </div>
          </div>
          <div className="profile_input_wrapper">
            <label>Product Price</label>
            <div className="input_wrapper">
              <input
                type="number"
                name="price"
                value={data.price}
                onChange={inputHandle}
                placeholder="Price"
                required
              />
            </div>
          </div>
          <div className="profile_input_wrapper">
            <label>Category</label>
            <div className="input_wrapper">
              <select
                name="cid"
                value={data.cid}
                onChange={inputHandle}
                required
              >
                <option value="">--Select--</option>
                {categories.map(({ id, name }) => (
                  <option value={id}>{name}</option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="profile_input_wrapper"
            style={{ alignItems: "flex-start" }}
          >
            <label>Product Description</label>
            <div className="input_wrapper quill_wrapper">
              <ReactQuill
                modules={{
                  toolbar: QUILL_TOOLBAR,
                }}
                value={data.desc}
                onChange={(value) =>
                  setData((prev) => ({ ...prev, desc: value }))
                }
              />
            </div>
          </div>
          <br />
          <br />
          <Button
            disabled={isDisable}
            type="submit"
          >
            {!isDisable ? (
              "Edit Product"
            ) : (
              <ClipLoader
                color="#FFFFFF"
                loading={true}
                cssOverride={LOADER_OVERRIDE}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default memo(EditProduct)
