import React from "react"
import Panel from "../layout/panel/panel"
import ProfileSetting from "../components/profile/profile"

const Profile = () => {
  return (
    <Panel>
      <ProfileSetting />
    </Panel>
  )
}

export default Profile
