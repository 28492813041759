import "./account.css"
import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import Switch from "react-switch"
import Loader from "../../components/loader/loader"
import Api from "../../api/api"

const AccountSettings = () => {
  const seller = useSelector((state) => state.seller)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [ratesData, setRatesData] = useState({
    marketingCommission: 0,
    deliveryCommission: 0,
    processingFee: 0,
    otherCharges: 0,
    salesTax: 0,
  })

  const inputHandle = async (id, label, from, to, isOpen) => {
    setData((prev) =>
      prev.map((item) =>
        item.label === label ? { id, label, from, to, isOpen } : item,
      ),
    )
    await Api.updateHours({ id, label, from, to, isOpen })
  }

  const ratesInputHandle = (e) => {
    const { name, value } = e.target
    setRatesData((prev) => ({ ...prev, [name]: value }))
  }

  const updatesRates = async (e) => {
    await Api.editRates(seller.id, ratesData)
  }

  const getHours = useCallback(async () => {
    const res = await Api.getHours(seller.id)
    if (res.status === 200) {
      setData(res.data.data)
    }
    setIsLoading(false)
  }, [])

  useLayoutEffect(() => {
    getHours()
  }, [])

  useEffect(() => {
    setRatesData({
      marketingCommission: seller?.marketingCommission,
      deliveryCommission: seller?.deliveryCommission,
      processingFee: seller?.processingFee,
      otherCharges: seller?.otherCharges,
      salesTax: seller?.salesTax,
    })
  }, [seller])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="profile_page_cont">
          <div className="c_card">
            <div className="title_3">Rates Setting</div>
            <form className="profile_form">
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <label>Marketing Commission (%)</label>
                  <input
                    type="number"
                    value={ratesData?.marketingCommission}
                    onChange={ratesInputHandle}
                    onBlur={updatesRates}
                    name="marketingCommission"
                    placeholder="Marketing Commission (%)"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <label>Delivery Commission (%)</label>
                  <input
                    type="number"
                    value={ratesData?.deliveryCommission}
                    onChange={ratesInputHandle}
                    onBlur={updatesRates}
                    name="deliveryCommission"
                    placeholder="Delivery Commission (%)"
                    required
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <label>Processing Fee (%)</label>
                  <input
                    type="number"
                    value={ratesData?.processingFee}
                    onChange={ratesInputHandle}
                    onBlur={updatesRates}
                    name="processingFee"
                    placeholder="Processing Fee (%)"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <label>Sales Tax (%)</label>
                  <input
                    type="number"
                    value={ratesData?.salesTax}
                    onChange={ratesInputHandle}
                    onBlur={updatesRates}
                    name="salesTax"
                    placeholder="Sales Tax (%)"
                    required
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <label>Other Charges (%)</label>
                  <input
                    type="number"
                    value={ratesData?.otherCharges}
                    onChange={ratesInputHandle}
                    onBlur={updatesRates}
                    name="otherCharges"
                    placeholder="Other Charges (%)"
                    required
                  />
                </div>
              </div>
            </form>
          </div>
          <br />
          <div className="c_card">
            <div className="title_3">Opening Hours</div>
            <form
              style={{ overflowX: "scroll" }}
              className="profile_form"
            >
              {data.map(({ id, label, from, to, isOpen }, i) => (
                <div
                  className="opening_hours_wrapper"
                  key={i}
                >
                  <span style={{ minWidth: 110 }}>{label}</span>
                  {isOpen && (
                    <>
                      <div className="input_wrapper">
                        <input
                          type="time"
                          name="from"
                          value={from}
                          onChange={(e) =>
                            inputHandle(id, label, e.target.value, to, isOpen)
                          }
                          required
                        />
                      </div>
                      <div className="input_wrapper">
                        <input
                          type="time"
                          name="to"
                          value={to}
                          onChange={(e) =>
                            inputHandle(id, label, from, e.target.value, isOpen)
                          }
                          required
                        />
                      </div>
                    </>
                  )}
                  <div className="icon_toggle">
                    <Switch
                      checked={isOpen}
                      onChange={(e) =>
                        inputHandle(id, label, from, to, !isOpen)
                      }
                      width={42}
                      height={20}
                      onColor="#4dc591"
                      handleDiameter={16}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    />
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default AccountSettings
