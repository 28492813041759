import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import logo from "../../assets/logo1.svg"
import { Notifications } from "../../helper/notifications"
import Api from "../../api/api"
import { LOADER_OVERRIDE } from "../../utils/constants"
import Button from "../../common/Button"

function ResetSection() {
  const history = useHistory()
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    email: "",
    password: "",
    password2: "",
    code: "",
  })

  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show)
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show)
  const handleMouseDownPassword2 = (e) => e.preventDefault()
  const handleMouseDownPassword1 = (e) => e.preventDefault()

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    if (data.password !== data.password2)
      return Notifications("warning", "Password doesn't match")
    setIsDisable(true)

    const res = await Api.resetPassword(data)
    if (res.status === 200) {
      Notifications("success", res.data.message)
      history.push("/login")
    }
    setIsDisable(false)
  }

  return (
    <>
      <div className="loginWrapper">
        <form
          className="formWrapper br10"
          onSubmit={submitHandle}
        >
          <p className="loginLogoWrapper text-center">
            <img
              src={logo}
              alt=""
              className="loginLogoImg"
            />
          </p>
          <br />
          <h2 className="loginHead text-center fw-bold">Reset Password</h2>
          <br />
          <br />
          <Box>
            <TextField
              required
              className="width100P"
              color="warning"
              id="outlined-basic"
              label="Email"
              name="email"
              value={data.email}
              onChange={inputHandle}
              variant="outlined"
            />
            <br />
            <br />

            <TextField
              required
              className="width100P"
              color="warning"
              id="outlined-basic1"
              label="Reset Code"
              name="code"
              value={data.code}
              onChange={inputHandle}
              variant="outlined"
            />
            <br />
            <br />
            <FormControl
              className="width100P"
              color="warning"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password1">
                New Password
              </InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password1"
                name="password"
                value={data.password}
                onChange={inputHandle}
                type={showPassword1 ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword1}
                      edge="end"
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <br />
            <br />
            <FormControl
              className="width100P"
              color="warning"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password2">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                required
                name="password2"
                value={data.password2}
                onChange={inputHandle}
                id="outlined-adornment-password2"
                type={showPassword2 ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <br />
            <br />
          </Box>
          <br />
          <p className="text-center">
            <Button
              disabled={isDisable}
              >
              {!isDisable ? (
                "Reset"
              ) : (
                <ClipLoader
                  color="#FFFFFF"
                  loading={true}
                  cssOverride={LOADER_OVERRIDE}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </Button>
          </p>
          <br />
          <p className="text-center">
            Don't have an account ?{" "}
            <Link to={"/register"}>
              <span className="cTheme pointer">Create one</span>
            </Link>
          </p>
        </form>
      </div>
    </>
  )
}

export default ResetSection
