import "./od.css"
import React, { useCallback, useLayoutEffect, useState } from "react"
import { Col, NavItem, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Api from "../../api/api"
import Loader from "../loader/loader"
import moment from "moment"

function OrderDescSection() {
  const { id: order_id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [orderDetail, setOrderDetail] = useState()

  const remainingTime = (timeString) => {
    const updatedTime = moment(timeString)
    const futureTime = updatedTime.add(45, "minutes")
    const remainingTimeInMinutes = futureTime.diff(moment(), "minutes")
    return remainingTimeInMinutes
  }

  const getOrderDetail = useCallback(async () => {
    const res = await Api.getOrderDetail(order_id)
    if (res.status === 200) {
      setOrderDetail(res.data.data)
      setIsLoading(false)
    }
  }, [setOrderDetail])

  useLayoutEffect(() => {
    getOrderDetail()
  }, [])
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section
            className="c_card"
            style={{ color: "white" }}
          >
            <Row style={{ rowGap: 16 }}>
              <Col
                lg={3}
                md={6}
                xs={12}
              >
                <div className="btheme px-3 py-4 br5">
                  <p>STATUS</p>
                  <h3 className="odPanels">{orderDetail?.status}</h3>
                </div>
              </Col>
              <Col
                lg={3}
                md={6}
                xs={12}
              >
                <div className="btheme px-3 py-4 br5">
                  <p>TYPE</p>
                  <h3 className="odPanels">{orderDetail?.type}</h3>
                </div>
              </Col>
              <Col
                lg={3}
                md={6}
                xs={12}
              >
                <div className="btheme px-3 py-4 br5">
                  <p>TOTAL PRICE</p>
                  <h3 className="odPanels">${orderDetail?.price}</h3>
                </div>
              </Col>
              <Col
                lg={3}
                md={6}
                xs={12}
              >
                <div className="btheme px-3 py-4 br5">
                  <p>TIME LEFT</p>
                  <h3 className="odPanels">
                    {["completed", "cancelled"].includes(
                      orderDetail?.status,
                    ) ? (
                      "-"
                    ) : remainingTime(orderDetail?.createdAt) > 0 ? (
                      <span>{remainingTime(orderDetail?.createdAt)} min</span>
                    ) : (
                      "0 Min"
                    )}
                  </h3>
                </div>
              </Col>
            </Row>
          </section>
          <br />
          <section className="c_card">
            <h6>
              <span style={{ minWidth: 150, display: "inline-block" }}>
                Payment ID:
              </span>
              {orderDetail?.paymentId}
            </h6>
            <h6>
              <span style={{ minWidth: 150, display: "inline-block" }}>
                Name:
              </span>
              {orderDetail?.name}
            </h6>
            <h6>
              <span style={{ minWidth: 150, display: "inline-block" }}>
                Email:
              </span>
              {orderDetail?.email}
            </h6>
            <h6>
              <span style={{ minWidth: 150, display: "inline-block" }}>
                Phone:
              </span>
              {orderDetail?.phone}
            </h6>
            <h6>
              <span style={{ minWidth: 150, display: "inline-block" }}>
                Address:
              </span>
              {orderDetail?.address}
            </h6>
            <h6>
              <span style={{ minWidth: 150, display: "inline-block" }}>
                Store:
              </span>
              {orderDetail?.restaurant.storeName}
            </h6>
            <h6>
              <span style={{ minWidth: 150, display: "inline-block" }}>
                Created At:
              </span>
              {moment(orderDetail?.createdAt).toLocaleString()}
            </h6>
            <br />
            <Row>
              <Col
                lg={8}
                md={12}
                xs={12}
              >
                {orderDetail?.order_products.map((item) => (
                  <div className="cartItem btheme br5 p2">
                    <Row>
                      <Col
                        lg={2}
                        md={2}
                        xs={0}
                      >
                        <img
                          src={item.restaurant_product.imgUrl}
                          className="cartItmImg br5 width100P"
                          style={{ aspectRatio: 4 / 3 }}
                        />
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        xs={3}
                      >
                        <span className="text-center cWhite">
                          {item.restaurant_product.name}
                        </span>
                      </Col>
                      <Col
                        lg={3}
                        md={3}
                        xs={3}
                      >
                        <div
                          className="cWhite"
                          style={{ textAlign: "center" }}
                        >
                          ${item.price}
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        xs={4}
                        style={{ textAlign: "center" }}
                        className="cWhite"
                      >
                        {item.quantity}x = ${item.price * item.quantity}
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>
              <Col
                lg={4}
                md={12}
                xs={12}
              >
                <div className="btheme p4 br5 cartsummary cWhite">
                  <h4 className="subsubHead mb-0">
                    Order ID #{orderDetail?.id}
                  </h4>
                  <br />
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Marketing Commission</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{orderDetail?.marketingCommission}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Delivery Commission</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{orderDetail?.deliveryCommission}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Processing Fee</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{orderDetail?.processingFee}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Sales Tax</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{orderDetail?.salesTax}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Other Charges</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>{orderDetail?.otherCharges}%</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead">Subtotal</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <p>${orderDetail.subTotal}</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                    >
                      <h6 className="subsubsubHead fw-bold">Total</h6>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                    >
                      <h6 className="subsubsubHead fw-bold">
                        ${orderDetail?.price}
                      </h6>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </section>
        </>
      )}
    </>
  )
}

export default OrderDescSection
