import { useCallback, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import Api from "../../api/api"
import Loader from "../loader/loader"
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function ProductsReviews() {
  const seller = useSelector((state) => state.seller)
  const [reviews, setReviews] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const options = {
    search: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "oid",
      label: "Order ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uid",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user",
      label: "Username",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user.name}</span>
        },
      },
    },
    {
      name: "review",
      label: "Review",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "rating",
      label: "Rating",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (time) => {
          const dateTime = new Date(time)
          return <span>{dateTime.toLocaleString()}</span>
        },
      },
    },
  ]

  const getReviews = useCallback(async () => {
    const res = await Api.getAllReviews(seller.id)
    if (res.status === 200) {
      setReviews(res.data.data)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [setReviews])

  useLayoutEffect(() => {
    getReviews()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="c_card">
          <Container fluid>
            <Row>
              <Col
                lg={8}
                md={8}
                xs={8}
              >
                <h2 className="text-left">Reviews</h2>
              </Col>
            </Row>
          </Container>
          <br />

          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                data={reviews}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </CacheProvider>
        </div>
      )}
    </>
  )
}

export default ProductsReviews
