import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import BTN from "../../common/Button"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"

import EditIcon from "@mui/icons-material/Edit"
import EyeIcon from "@mui/icons-material/RemoveRedEyeSharp"
import createCache from "@emotion/cache"
import { useHistory } from "react-router-dom"
import { CLIENT_LINK } from "../../utils/constants"
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function ProductsList({ data }) {
  const history = useHistory()

  const options = {
    search: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "price",
      label: "Price($)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowData[3].name}</span>
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>{new Date(tableMeta.rowData[4]).toLocaleDateString()}</span>
          )
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <EditIcon
                className="cGreen pointer"
                onClick={() => handleEdit(tableMeta)}
              />
              <EyeIcon
                className="pointer"
                onClick={() => handleView(tableMeta)}
              />
            </>
          )
        },
      },
    },
  ]

  async function handleView(meta) {
    window.open(`${CLIENT_LINK}/product-detail/${meta.rowData[0]}`, "_blank")
  }

  async function handleEdit(meta) {
    history.push(`/product/edit/${meta.rowData[0]}`)
  }

  return (
    <>
      <div className="c_card">
        <Container fluid>
          <Row>
            <Col
              lg={8}
              md={8}
              xs={8}
            >
              <h2 className="text-left">Products</h2>
            </Col>
            <Col
              lg={4}
              md={4}
              xs={4}
            >
              <p className="text-right">
                <BTN
                  margin="0 5px 5px 0"
                  onClick={() => history.push(`/product/new`)}
                >
                  Add Product
                </BTN>
              </p>
            </Col>
          </Row>
        </Container>
        <br />

        <CacheProvider value={muiCache}>
          <ThemeProvider theme={createTheme()}>
            <MUIDataTable
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </>
  )
}

export default ProductsList
