import Panel from "../layout/panel/panel"
import OrderDescSection from "../components/order_description/od"

function OrderDescription() {
  return (
    <Panel>
      <OrderDescSection />
    </Panel>
  )
}

export default OrderDescription
