import axios from "axios"
import Cookies from "js-cookie"
import { errorHandler } from "../helper/errorhandler"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../utils/constants"

//axios.defaults.baseURL = "http://localhost:4000/api/v1/"
axios.defaults.baseURL = "https://backend.mealsbus.com/api/v1/"

const getJwt = () => {
  if (
    Cookies.get(JWT_STORAGE_NAME) !== undefined &&
    localStorage.getItem(JWT_STORAGE_NAME)
  ) {
    return Cookies.get(JWT_STORAGE_NAME)
    // return localStorage.getItem(JWT_STORAGE_NAME)
  } else {
    Cookies.remove(JWT_STORAGE_NAME)
    localStorage.removeItem(JWT_STORAGE_NAME)
    localStorage.removeItem(LOCAL_STORAGE_NAME)
  }
}

const setJwt = (token) => {
  Cookies.set(JWT_STORAGE_NAME, token, {
    expires: 2,
    path: "/",
    secure: false,
  })
  localStorage.setItem(JWT_STORAGE_NAME, token)
}

const setAuthHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
  setJwt(token)
}

if (getJwt()) {
  setAuthHeader(getJwt())
}

export default class Api {
  //login
  static login = async (data) => {
    try {
      const res = await axios.post(`delivery/login`, data)
      setAuthHeader(res.data.token)
      return res
    } catch (error) {
      localStorage.removeItem(JWT_STORAGE_NAME)
      localStorage.removeItem(LOCAL_STORAGE_NAME)
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //register
  static register = async (data) => {
    try {
      const res = await axios.put(`delivery/register`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //forget password
  static forgetPassword = async (data) => {
    try {
      const res = await axios.post(`delivery/password/forget`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //reset password
  static resetPassword = async (data) => {
    try {
      const res = await axios.put(`delivery/password/reset`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //verify
  static verifyAccount = async (data) => {
    try {
      const res = await axios.put(`user/verify`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get me profile
  static getMeProfile = async () => {
    try {
      const res = await axios.get(`delivery/me`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get me profile
  static getDeliveryCounters = async (id) => {
    try {
      const res = await axios.get(`delivery/counters/`+id)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //update profile
  static updateProfile = async (data) => {
    try {
      const res = await axios.put(`delivery/update`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //update password
  static updatePassword = async (data) => {
    try {
      const res = await axios.put(`delivery/password/change`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // //get hours
  // static getHours = async (id) => {
  //   try {
  //     const res = await axios.get(`/api/v1/restaurant/hours/${id}`)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //update hours
  // static updateHours = async (data) => {
  //   try {
  //     const res = await axios.put(`/api/v1/restaurant/hours`, data)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //get categories
  // static getCategories = async (id) => {
  //   try {
  //     const res = await axios.get(`/api/v1/restaurant/category/${id}`)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //add category
  // static addCategory = async (data) => {
  //   try {
  //     const res = await axios.post(`/api/v1/restaurant/category`, data)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //update category
  // static updateCategory = async (data) => {
  //   try {
  //     const res = await axios.put(`/api/v1/restaurant/category`, data)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //get All product by RID
  // static getProductsByRID = async (rid) => {
  //   try {
  //     const res = await axios.get(`/api/v1/restaurant/product/all/${rid}`)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //get product by ID
  // static getProductByID = async (pid) => {
  //   try {
  //     const res = await axios.get(`/api/v1/restaurant/product/${pid}`)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //add product
  // static addProduct = async (data) => {
  //   try {
  //     const res = await axios.post(`/api/v1/restaurant/product`, data)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //edit product
  // static editProduct = async (data) => {
  //   try {
  //     const res = await axios.put(`/api/v1/restaurant/product`, data)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // //edit rates
  // static editRates = async (id, data) => {
  //   try {
  //     const res = await axios.put(`/api/v1/restaurant/update-taxes/${id}`, data)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }

  //get orders
  static getOrders = async (id) => {
    try {
      const res = await axios.get(`order/did/${id}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //update order
  static updateOrderStatus = async (data) => {
    try {
      const res = await axios.put(`order`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // //get users by order
  // static getOrderUsers = async (id) => {
  //   try {
  //     const res = await axios.get(`/api/v1/order/users/${id}`)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }

  // getOrderDetail
  static getOrderDetail = async (id) => {
    try {
      const res = await axios.get(`order/oid/${id}`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // postNotification
  static postNotification = async (data) => {
    try {
      const res = await axios.post(`notification/add`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // // getNotification
  // static getNotifications = async (id) => {
  //   try {
  //     const res = await axios.get(`/api/v1/notification/uid/${id}/seller`)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // // updateNotification
  // static updateNotification = async (id) => {
  //   try {
  //     const res = await axios.put(`/api/v1/notification/update/seen/${id}`)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }
  //
  // // get Reviews
  // static getAllReviews = async (rid) => {
  //   try {
  //     const res = await axios.get(`/api/v1/reviews/${rid}`)
  //     return res
  //   } catch (error) {
  //     errorHandler(error.response.data)
  //     return error.response.data
  //   }
  // }


}
