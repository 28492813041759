import React from "react"
import Panel from "../layout/panel/panel"
import UsersSection from "../components/users/users"

const Users = () => {
  return (
    <Panel>
      <UsersSection />
    </Panel>
  )
}

export default Users
