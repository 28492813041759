import { useCallback, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import ProductsList from "../components/products/products"
import Panel from "../layout/panel/panel"
import Loader from "../components/loader/loader"
import Api from "../api/api"

const Products = () => {
  const { id } = useSelector((state) => state.seller)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])

  const getProducts = useCallback(async () => {
    const res = await Api.getProductsByRID(id)
    if (res.status === 200) {
      setData(res.data.data)
    }
    setIsLoading(false)
  }, [])

  useLayoutEffect(() => {
    getProducts()
  }, [])
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Panel>
          <ProductsList data={data} />
        </Panel>
      )}
    </>
  )
}

export default Products
