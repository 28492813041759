import React, { useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import CustomModal from "../../layout/modal/modal"
import Button from "../../common/Button"
import Api from "../../api/api"
import { LOADER_OVERRIDE } from "../../utils/constants"

const AddCategory = ({ setAddModalShow, getCategories }) => {
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({ name: "" })
  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)
    const res = await Api.addCategory(data)
    if (res.status === 201) {
      getCategories()
      setAddModalShow(false)
    }
    setIsDisable(false)
  }
  return (
    <CustomModal
      title="Add Category"
      onHide={() => setAddModalShow(false)}
    >
      <form onSubmit={submitHandle}>
        <div className="input_wrapper">
          <input
            type="text"
            value={data.name}
            onChange={(e) => setData({ name: e.target.value })}
            placeholder="Category Name"
            required
          />
        </div>
        <Button
          width="100%"
          margin="0 5px 5px 0"
        >
          {!isDisable ? (
            "Add New"
          ) : (
            <ClipLoader
              color="#FFFFFF"
              loading={true}
              cssOverride={LOADER_OVERRIDE}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </Button>
      </form>
    </CustomModal>
  )
}

export default AddCategory
