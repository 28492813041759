import Panel from "../layout/panel/panel"
import ProductReviews from "../components/reviews/reviews"

const Reviews = () => {
  return (
    <Panel>
      <ProductReviews />
    </Panel>
  )
}

export default Reviews
