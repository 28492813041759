import { useState, useCallback } from "react"
import { useSelector } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import Loader from "../../components/loader/loader"
import Api from "../../api/api"
import { useLayoutEffect } from "react"

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function UsersSection() {
  const seller = useSelector((state) => state.seller)
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState(false)
  const options = {
    search: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  }

  const columns = [
    {
      name: "user",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user.id}</span>
        },
      },
    },
    {
      name: "user",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user.name}</span>
        },
      },
    },
    {
      name: "user",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user.email}</span>
        },
      },
    },
    {
      name: "user",
      label: "Phone",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user.phone}</span>
        },
      },
    },
    {
      name: "user",
      label: "Address",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user.address}</span>
        },
      },
    },
    {
      name: "user",
      label: "City",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user.city}</span>
        },
      },
    },
    {
      name: "user",
      label: "Country",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user.country}</span>
        },
      },
    },
    {
      name: "orders",
      label: "Orders",
      options: {
        filter: true,
        sort: true,
      },
    },
  ]

  const getUsers = useCallback(async () => {
    const res = await Api.getOrderUsers(seller.id)
    if (res.status === 200) {
      setUsers(res.data.data)
      setIsLoading(false)
    }
  }, [setUsers])

  useLayoutEffect(() => {
    getUsers()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="c_card">
          <Container fluid>
            <Row>
              <Col
                lg={8}
                md={8}
                xs={8}
              >
                <h2 className="text-left">Users</h2>
              </Col>
            </Row>
          </Container>
          <br />

          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                data={users}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </CacheProvider>
        </div>
      )}
    </>
  )
}

export default UsersSection
