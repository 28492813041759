import React from "react"
import Panel from "../layout/panel/panel"
import OrderSection from "../components/orders/orders"

const Orders = () => {
  return (
    <Panel>
      <OrderSection />
    </Panel>
  )
}

export default Orders
