import { useCallback, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import Panel from "../layout/panel/panel"
import Loader from "../components/loader/loader"
import AddProduct from "../components/products/add"
import Api from "../api/api"

const AddProducts = () => {
  const { id } = useSelector((state) => state.seller)
  let [categories, setCategories] = useState([])
  let [isLoading, setIsLoading] = useState(true)

  const getAllCategories = useCallback(async () => {
    let res = await Api.getCategories(id)
    if (res.status == 200) {
      setCategories(res.data.data)
      setIsLoading(false)
    }
  }, [setCategories])

  useLayoutEffect(() => {
    getAllCategories()
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <Panel>
        <AddProduct categories={categories} />
      </Panel>
    </>
  )
}

export default AddProducts
