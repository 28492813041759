import React, { memo, useLayoutEffect, useState } from "react"
import Button from "../../common/Button"
import CustomModal from "../../layout/modal/modal"
import { LOADER_OVERRIDE } from "../../utils/constants"
import ClipLoader from "react-spinners/ClipLoader"
import Api from "../../api/api"

const EditCategory = ({ id, name, setEditModalShow, getCategories }) => {
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({ id: "", name: "" })

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)
    
    const res = await Api.updateCategory(data)
    if (res.status === 200) {
      getCategories()
      setEditModalShow(false)
    }
    setIsDisable(false)
  }

  useLayoutEffect(() => {
    setData({ id, name })
  }, [])

  return (
    <CustomModal
      title="Edit Category"
      onHide={() => setEditModalShow(false)}
    >
      <form onSubmit={submitHandle}>
        <div className="input_wrapper">
          <input
            type="text"
            value={data.name}
            onChange={(e) =>
              setData((prev) => ({ ...prev, name: e.target.value }))
            }
            placeholder="Category Name"
            required
          />
        </div>
        <Button
          width="100%"
          margin="0 5px 5px 0"
        >
          {!isDisable ? (
            "Update"
          ) : (
            <ClipLoader
              color="#FFFFFF"
              loading={true}
              cssOverride={LOADER_OVERRIDE}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </Button>
      </form>
    </CustomModal>
  )
}

export default memo(EditCategory)
