import Home from "../pages/home"
import Profile from "../pages/profile"
import Orders from "../pages/orders"
import Users from "../pages/users"
import Account from "../pages/account"
import Categories from "../pages/categories"
import Products from "../pages/products"
import Reviews from "../pages/reviews"
import AddProduct from "../pages/addProduct"
import EditProduct from "../pages/editProduct"
import Login from "../pages/login"
import Register from "../pages/register"
import VerifyAccount from "../pages/verifyAccount"
import Forget from "../pages/forgot"
import Reset from "../pages/reset"
import Password from "../pages/password"
import OrderDescription from "../pages/order_description"

export const ROUTES = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/verify",
    component: VerifyAccount,
  },
  {
    path: "/forgot",
    component: Forget,
  },
  {
    path: "/reset",
    component: Reset,
  },
  {
    path: "/",
    component: Home,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/account",
    component: Account,
  },
  {
    path: "/password",
    component: Password,
  },
  {
    path: "/orders",
    component: Orders,
  },
  {
    path: "/order/:id",
    component: OrderDescription,
  },
  {
    path: "/users",
    component: Users,
  },
  {
    path: "/categories",
    component: Categories,
  },
  {
    path: "/products",
    component: Products,
  },
  {
    path: "/reviews",
    component: Reviews,
  },
  {
    path: "/product/new",
    component: AddProduct,
  },
  {
    path: "/product/edit/:id",
    component: EditProduct,
  },
]
