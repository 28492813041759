import React, { useState, useLayoutEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import moment from "moment"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"
import EyeIcon from "@mui/icons-material/RemoveRedEyeSharp"
import EditIcon from "@mui/icons-material/Edit"
import createCache from "@emotion/cache"
import CustomModal from "../../layout/modal/modal"
import Button from "../../common/Button"
import Loader from "../../components/loader/loader"
import Api from "../../api/api"
import { CLIENT_LINK } from "../../utils/constants"

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function OrderSection() {
  const seller = useSelector((state) => state.seller)
  const [orders, setOrders] = useState([])
  const [editOrderDetail, setEditOrderDetail] = useState({
    uid: 0,
    id: 0,
    status: "",
  })
  const [isLoading, setIsLoading] = useState(true)
  const [editModalShow, setEditModalShow] = useState(false)

  const options = {
    search: true,
    download: true,
    print: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none", // toggle checkbox
  }

  const columns = [
    {
      name: "id",
      label: "Order ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "paymentId",
      label: "Payment ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (price) => {
          return <span>${price}</span>
        },
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (order_status) => {
          const style = {
            padding: "4px 8px",
            fontSize: "12px",
            borderRadius: "5px",
            fontWeight: "bold",
            background:
              order_status === "dispatched"
                ? "rgba(145, 158, 171, 0.16)"
                : order_status === "pending"
                ? "rgba(255, 171, 0, 0.16)"
                : order_status === "cancelled"
                ? "rgba(255, 86, 48, 0.16)"
                : order_status === "delivered"
                ? "rgba(0, 184, 217, 0.16)"
                : "rgba(54, 179, 126, 0.16)",
            color:
              order_status === "dispatched"
                ? "rgb(33, 43, 54)"
                : order_status === "pending"
                ? "rgb(183, 110, 0)"
                : order_status === "cancelled"
                ? "rgb(183, 29, 24)"
                : order_status === "delivered"
                ? "rgb(0, 108, 156)"
                : "rgb(27, 128, 106)",
          }
          return <span style={style}> {order_status}</span>
        },
      },
    },
    {
      name: "createdAt",
      label: "Time Left",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (timeString, tableMeta) => {
          const updatedTime = moment(timeString)
          const futureTime = updatedTime.add(45, "minutes")
          const remainingTimeInMinutes = futureTime.diff(moment(), "minutes")
          return (
            <>
              {["completed", "cancelled"].includes(tableMeta.rowData[5]) ? (
                "-"
              ) : remainingTimeInMinutes > 0 ? (
                <span>{remainingTimeInMinutes} min</span>
              ) : (
                "0 Min"
              )}
            </>
          )
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (time) => {
          const dateTime = new Date(time)
          return <span>{dateTime.toLocaleString()}</span>
        },
      },
    },
    {
      name: "uid",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (uid, tableMeta) => {
          return (
            <>
              <Link to={`/order/${tableMeta.rowData[0]}`}>
                <EyeIcon className="pointer" />
              </Link>
              &nbsp;&nbsp;
              <EditIcon
                className="cGreen pointer"
                onClick={() => [
                  setEditOrderDetail({
                    uid,
                    id: tableMeta.rowData[0],
                    status: tableMeta.rowData[5],
                  }),
                  setEditModalShow(true),
                ]}
              />
            </>
          )
        },
      },
    },
  ]

  const updateOrderStatus = async () => {
    await Api.updateOrderStatus(editOrderDetail)
    let message
    if (editOrderDetail.status === "delivered") {
      message = `${seller.storeName} ${editOrderDetail.status} your order #${editOrderDetail.id}. Please give a feedback.`
    } else {
      message = `${seller.storeName} ${editOrderDetail.status} your order #${editOrderDetail.id}`
    }
    getOrders()
    await Api.postNotification({
      to_id: editOrderDetail.uid,
      to: "user", //user, seller
      message,
      link: `${CLIENT_LINK}/dashboard/order/${editOrderDetail.id}`,
    })
    setEditModalShow(false)
  }

  const getOrders = useCallback(async () => {
    const res = await Api.getOrders(seller.id)
    if (res.status === 200) {
      setOrders(res.data.data)
      setIsLoading(false)
    }
  }, [setOrders])

  useLayoutEffect(() => {
    getOrders()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="c_card">
          <Container fluid>
            <Row>
              <Col
                lg={8}
                md={8}
                xs={8}
              >
                <h2 className="text-left">Orders</h2>
              </Col>
            </Row>
          </Container>
          <br />

          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                data={orders}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </CacheProvider>

          {/* Edit Model */}
          {editModalShow && (
            <CustomModal
              title="Update Order Status"
              onHide={() => setEditModalShow(false)}
            >
              <div className="input_wrapper">
                <select
                  value={editOrderDetail.status}
                  onChange={(e) =>
                    setEditOrderDetail((prev) => ({
                      ...prev,
                      status: e.target.value,
                    }))
                  }
                >
                  <option value="pending">Pending</option>
                  <option value="dispatched">Dispatched</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="delivered">Delivered</option>
                  {/* <option value="completed">Completed</option> */}
                </select>
              </div>
              <Button
                margin="0 5px 5px 0"
                onClick={updateOrderStatus}
              >
                Update
              </Button>
            </CustomModal>
          )}
        </div>
      )}
    </>
  )
}

export default OrderSection
