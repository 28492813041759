import { configureStore } from "@reduxjs/toolkit"
import sidebarSlice from "./slices/sidebarSlice"
import sellerSlice from "./slices/sellerSlice"

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    seller: sellerSlice,
  },
})
