import { useCallback, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import Panel from "../layout/panel/panel"
import Loader from "../components/loader/loader"
import EditProduct from "../components/products/edit"
import Api from "../api/api"

const EditProducts = () => {
  const { id } = useParams()
  const history = useHistory()
  const { id: rid } = useSelector((state) => state.seller)
  let [categories, setCategories] = useState([])
  let [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    name: "",
    cid: "",
    image: "",
    imageURL: "",
    price: 0,
    desc: "",
  })

  const getAllCategories = useCallback(async () => {
    let res = await Api.getCategories(rid)
    if (res.status == 200) {
      setCategories(res.data.data)
    }
  }, [setCategories])

  const getProductById = useCallback(async () => {
    let res = await Api.getProductByID(id)
    if (res.status == 200) {
      setData({
        name: res.data.data.name,
        cid: res.data.data.cid,
        imageURL: res.data.data.imgUrl,
        price: res.data.data.price,
        desc: res.data.data.desc,
      })
    } else if (res.status === 404) {
      history.push("/products")
    }
    setIsLoading(false)
  }, [setData])

  useLayoutEffect(() => {
    getAllCategories()
    getProductById()
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <Panel>
        <EditProduct
          id={id}
          categories={categories}
          data={data}
          setData={setData}
        />
      </Panel>
    </>
  )
}

export default EditProducts
