import AccountSettings from "../components/account/account"
import Panel from "../layout/panel/panel"

const Account = () => {
  return (
    <Panel>
      <AccountSettings />
    </Panel>
  )
}

export default Account
