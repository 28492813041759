import "./header.css"
import { useState, useRef, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggleSidebar } from "../../store/slices/sidebarSlice"
import Usermenu from "../usermenu/usermenu"
import Api from "../../api/api"
import Notifications from "../notifications/notifications"

const Header = () => {
  const seller = useSelector((state) => state.seller)
  const dispatch = useDispatch()
  const showMenuRef = useRef()
  const [showMenu, setShowMenu] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [showNoti, setShowNoti] = useState(false)
  const showNotiRef = useRef()

  const getNotifications = useCallback(async () => {
    const res = await Api.getNotifications(seller?.id)
    if (res.status === 200) {
      setNotifications(res.data.data)
    }
  }, [setNotifications])

  useEffect(() => {
    // getNotifications()
  }, [])

  return (
    <header className="panel_header c_card">
      <div className="header_cont">
        <div className="header_left">
          <div className="hamburger">
            <span onClick={() => dispatch(toggleSidebar())}>
              <i className="bi bi-list"></i>
            </span>
          </div>
        </div>
        <div className="header_center"></div>
        <div className="header_right">
          {/*<div className="notifications position-relative">*/}
          {/*  <span onClick={() => setShowNoti(true)}>*/}
          {/*    <i className="bi bi-bell-fill"></i>*/}
          {/*    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">*/}
          {/*      {notifications.filter((item) => !item.seen).length}*/}
          {/*    </span>*/}
          {/*  </span>*/}
          {/*  {showNoti && (*/}
          {/*    <Notifications*/}
          {/*      notifications={notifications}*/}
          {/*      setShowNoti={setShowNoti}*/}
          {/*      showNotiRef={showNotiRef}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</div>*/}
          &nbsp;
          <div
            className="avatar"
            onClick={() => setShowMenu(true)}
          >
            <img
              src={seller?.avatar}
              alt="profile"
            />
            {showMenu && (
              <Usermenu
                avatar={seller?.avatar}
                name={seller?.storeName}
                email={seller?.email}
                showMenuRef={showMenuRef}
                setShowMenu={setShowMenu}
              />
            )}
          </div>
          <span onClick={() => setShowMenu(true)}>{seller?.storeName}</span>
        </div>
      </div>
    </header>
  )
}

export default Header
