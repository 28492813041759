import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import "./App.css"
import { useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import decode from "jwt-decode"
import { ToastContainer } from "react-toastify"
import Cookies from "js-cookie"
import ProtectedAuth from "./protectedAuth"
import ProtectedRoute from "./protectedRoute"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "./utils/constants"
import { ROUTES } from "./utils/routes"
import { getMe } from "./store/slices/sellerSlice"
import { hideSidebar } from "./store/slices/sidebarSlice"

function App() {
  const location = useLocation()
  const dispatch = useDispatch()

  const verifyToken = () => {
    const token = Cookies.get(JWT_STORAGE_NAME)
    if (token) {
      const decode_token = decode(token)
      if (
        decode_token.exp * 1000 < new Date().getTime() ||
        Cookies.get(JWT_STORAGE_NAME) === undefined ||
        localStorage.getItem(LOCAL_STORAGE_NAME) === "undefined"
      ) {
        Cookies.remove(JWT_STORAGE_NAME)
        localStorage.removeItem(JWT_STORAGE_NAME)
        localStorage.removeItem(LOCAL_STORAGE_NAME)
        window.location.reload()
      }
    }
  }

  useLayoutEffect(() => {
    verifyToken()
  }, [location.pathname])

  useLayoutEffect(() => {
    dispatch(hideSidebar())
  }, [location.pathname])

  useLayoutEffect(() => {
    if (Cookies.get(JWT_STORAGE_NAME) !== undefined) {
      dispatch(getMe())
    }
  }, [])

  return (
    <>
      {ROUTES.map((route, index) => (
        <ProtectedAuth
          exact
          {...route}
          key={index}
        />
      )).slice(0, 5)}
      {ROUTES.map((route, index) => (
        <ProtectedRoute
          exact
          {...route}
          key={index}
        />
      )).slice(5)}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme={"light"}
      />
    </>
  )
}

export default App
