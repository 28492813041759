import React, { useCallback, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import BTN from "../../common/Button"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"

import EditIcon from "@mui/icons-material/Edit"
import createCache from "@emotion/cache"
import AddCategory from "./addCategory"
import EditCategory from "./editCategory"
import Api from "../../api/api"
import Loader from "../loader/loader"
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function CategoriesCmp() {
  const seller = useSelector((state) => state.seller)
  const [isLoading, setIsLoading] = useState(true)
  const [addModalShow, setAddModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState(false)
  const [data, setData] = useState([])
  const [editCategory, setEditCategory] = useState({ id: "", name: "" })

  const options = {
    search: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <EditIcon
                className="cGreen pointer"
                onClick={() => handleEdit(tableMeta)}
              />
            </>
          )
        },
      },
    },
  ]

  async function handleEdit(meta) {
    setEditCategory({ id: meta.rowData[0], name: meta.rowData[1] })
    setEditModalShow(true)
  }

  const getCategories = useCallback(async () => {
    const res = await Api.getCategories(seller.id)
    if (res.status === 200) {
      setData(res.data.data)
      setIsLoading(false)
    }
  }, [setData])

  useLayoutEffect(() => {
    getCategories()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="c_card">
          <Container fluid>
            <Row>
              <Col
                lg={8}
                md={8}
                xs={8}
              >
                <h2 className="text-left">Categories</h2>
              </Col>
              <Col
                lg={4}
                md={4}
                xs={4}
              >
                <p className="text-right">
                  <BTN
                    margin="0 5px 5px 0"
                    onClick={() => {
                      setAddModalShow(true)
                    }}
                  >
                    Add Category
                  </BTN>
                </p>
              </Col>
            </Row>
          </Container>
          <br />

          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </CacheProvider>

          {/* AddCategory */}
          {addModalShow && (
            <AddCategory
              setAddModalShow={setAddModalShow}
              getCategories={getCategories}
            />
          )}

          {/* EditCategory */}
          {editModalShow && (
            <EditCategory
              id={editCategory.id}
              name={editCategory.name}
              setEditModalShow={setEditModalShow}
              getCategories={getCategories}
            />
          )}
        </div>
      )}
    </>
  )
}

export default CategoriesCmp
