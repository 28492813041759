import "./usermenu.css"
import { memo, useEffect } from "react"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../../utils/constants"

const Usermenu = ({ avatar, name, email, showMenuRef, setShowMenu }) => {
  const logoutHandler = () => {
    Cookies.remove(JWT_STORAGE_NAME)
    localStorage.removeItem(JWT_STORAGE_NAME)
    localStorage.removeItem(LOCAL_STORAGE_NAME)
    window.location.reload()
  }

  useEffect(() => {
    let handler = (e) => {
      if (!showMenuRef.current.contains(e.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener("mousedown", handler)
    return () => document.removeEventListener("mousedown", handler)
  }, [])
  return (
    <div
      ref={showMenuRef}
      className="usermenu"
    >
      <div className="usermenu_head">
        <img
          src={avatar}
          alt=""
        />
        <div>
          <p>{name}</p>
          <span>{email}</span>
        </div>
      </div>
      <div className="usermenu_body">
        <div className="username_links">
          <Link to={`/profile`}>Profile Settings</Link>
          <a onClick={logoutHandler}>
            <i className="bi bi-box-arrow-right"></i> Logout
          </a>
        </div>
      </div>
    </div>
  )
}

export default memo(Usermenu)
