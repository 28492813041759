import "./register.css"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"
import TextField from "@mui/material/TextField"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Button from "../../common/Button"
import logo from "../../assets/logo1.svg"
import { LOADER_OVERRIDE } from "../../utils/constants"
import Api from "../../api/api"
import { Notifications } from "../../helper/notifications"

function RegisterRestaurantSection() {
  const history = useHistory()
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    city: "",
    country: "",
  })

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)

    const res = await Api.register(data)
    if (res.status === 201) {
      Notifications("success", res.data.message)
      history.push("/verify")
    }
    setIsDisable(false)
  }

  return (
    <>
      <div className="loginWrapper">
        <form
          onSubmit={submitHandle}
          className="rrWrapper br10"
        >
          <p className="loginLogoWrapper text-center">
            <img
              src={logo}
              alt=""
              className="loginLogoImg"
            />
          </p>
          <br />
          <h2 className="loginHead text-center fw-bold">Register Delivery</h2>
          <br />

          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic3"
                  label="Name"
                  variant="outlined"
                  name="name"
                  type="text"
                  value={data.name}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br/>
          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic9"
                  type="email"
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={data.email}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic10"
                  type="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  value={data.password}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic11"
                  label="Phone Number"
                  variant="outlined"
                  name="phone"
                  type="number"
                  value={data.phone}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                  lg={12}
                  md={12}
                  xs={12}
              >
                <TextField
                    className="width100P"
                    color="warning"
                    id="outlined-basic3"
                    label="Address"
                    variant="outlined"
                    name="address"
                    type="text"
                    value={data.address}
                    onChange={inputHandle}
                    required
                />
              </Col>
            </Row>
          </Container>
          <br/>
          <Container fluid>
            <Row>
              <Col
                  lg={6}
                  md={12}
                  xs={12}
              >
                <TextField
                    className="width100P"
                    color="warning"
                    id="outlined-basic5"
                    label="City"
                    variant="outlined"
                    name="city"
                    type="text"
                    value={data.city}
                    onChange={inputHandle}
                    required
                />
              </Col>
              <br />
              <br />
              <br />
              <Col
                  lg={6}
                  md={12}
                  xs={12}
              >
                <TextField
                    className="width100P"
                    color="warning"
                    id="outlined-basic6"
                    label="Country"
                    variant="outlined"
                    name="country"
                    type="text"
                    value={data.country}
                    onChange={inputHandle}
                    required
                />
              </Col>
            </Row>
          </Container>
          <br />

          <p className="text-center">
            By clicking "Submit," you agree to{" "}
            <span className="cTheme pointer">
              Mealsbus Terms and Conditions
            </span>{" "}
            and acknowledge you have read the{" "}
            <span className="cTheme pointer">Privacy Policy's</span>.
            <Link to={"/register"}></Link>
          </p>
          <br />
          <p className="text-center">
            <Button disabled={isDisable}>
              {!isDisable ? (
                "Submit"
              ) : (
                <ClipLoader
                  color="#FFFFFF"
                  loading={true}
                  cssOverride={LOADER_OVERRIDE}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </Button>
          </p>
          <br />
          <p className="text-center">
            Already have an account ?{" "}
            <Link to={"/login"}>
              <span className="cTheme pointer">Login</span>
            </Link>
          </p>
        </form>
      </div>
    </>
  )
}

export default RegisterRestaurantSection
