import React from 'react'
import Panel from '../layout/panel/panel'
import PasswordSection from "../components/password/password"

const Password = () => {
  return (
    <Panel>
        <PasswordSection/>
    </Panel>
  )
}

export default Password